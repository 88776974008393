.image-flip-container {
    position: relative;
}

#image-flip__dark {
    position: absolute;
    top: 0;
    width: 100%;
}

.houben-movie {
    background: #171817;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.houben-movie-holder {
    position: relative;
    width: 90%;
    max-width: 1680px;
    margin-left: -5%;
    margin-bottom: -7em;
    margin-top: 6%;

    video {
        position: absolute;
        z-index: 1;
        top: 5.5%;
        left: 3.8%;
        width: 93.9%;
    }

    img {
        position: relative;
        z-index: 2;
    }
}