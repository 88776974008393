// Le Content Pages

:root {
  --header-height-regular-content: calc(90vh - 4em);
}

.regular-content {
  margin-top: var(--header-height-regular-content);
  top: 0;
  position: relative;
  background: $white;
  z-index: 1;

  > section:first-child {
    padding-top: 5em;

    @include media-breakpoint-down(sm) {
      padding-top: 3em;
    }
  }
}
body.content header {
  position: fixed;
  //margin-top:-10em;
  //margin-bottom:5em;
  top: 0;
  background-color: $gray-light;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: var(--header-height-regular-content);
  width: 100%;

  img {
    flex: 1 1;
    max-width: 1440px;
    height: auto;

    padding: 10vh 0;

    @media (max-width: 1440px) {
      flex: 1 1;
      max-width: 90vw;
      //padding-top:calc(10vh + 10em);

    }
  }
  .arrow-container {
    height:6em;
    display:flex;
    align-items: flex-end;
    justify-content: center;

    @include media-breakpoint-down(md) {
      height: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      color:$red;
      &:hover {
        color:black;
      }
    }
    img, svg {
      width:1.2em;
      height:auto;
      margin:0 0;
    }
  }
}

.regular-content-no-header {
  margin-top:5em;

  @include media-breakpoint-up(sm) {
    margin-top: 14em;
  }
}