// Scrubbing Video of MoveMi

div.video {
    //height:100vh;
    max-height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
canvas {
    grid-column: 4 /12;
    width: 100%;
    height: 100%;
    max-height: 63vh;
    object-fit: contain;
}

.claim-holder {
    grid-column: 4/12;
}


#sleep__holder,
#move__holder,
#play__holder,
#naolima__holder {
    width: 100%;
    //min-height: 35vw;
    height: auto;
    position: relative;
    z-index:1;
    overflow-x: hidden;
}


#naolima {
    position: relative;
    width: fit-content;
    min-height: 24vw;
    right: -30%;

    img {
        height:24vw;
        width:auto;
    }
}
#naolima__holder {
    height:24vw;
    margin-top: -12.5vw;
    margin-bottom: -2vw;
}

// helper class
.zIndex2 {
    position: relative;
    z-index:2;
}