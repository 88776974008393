// extending the grid with one more tier:

@media (min-width: 1680px) {
  .container {
    max-width: 1500px;
  }
}

// overriding ALL the viewport max-widths, fluid until 1500!

.container {
  //max-width: 1500px !important;
  //padding:clamp(15px, 5vw, 5vw);
}
