// Le new Split header

:root {
  //--header-height-split-screen: calc(95vh - 4em);
  --header-height-split-screen: 50vw;
  --header-height-split-screen-mobile: 100vh;
}

.regular-content {
  margin-top: var(--header-height-split-screen-mobile);
}

body.content header.split {
  align-items: flex-start;
  height: var(--header-height-split-screen-mobile);

  img, svg {
    padding: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
  }
  
  .arrow-container {
    flex: 0 0 50px;
    justify-self: flex-end;
  }

  .split-screen {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    &__type {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      min-height: 30vh;
      padding: 15px;
      border-bottom:7px solid var(--red);

      .hgroup {
        display: flex;
        align-items: flex-end;
      }

      h1 {
        //font-size: 2.4em;
        flex: 1;

        span {
          color: $red;
          //font-size: .43em;
        }
      }

      &.darkheader {
        background-color: black;

        h1 {
          color: white;
        }
      }
      &.redheader {
        background-color: var(--red);
        border-bottom-color: var(--warm-gray-darker);

        .hgroup h1 {
          color: white;

          span {
            color:black;
          }
        }
      }
    }

    &__image {
      flex: 1 0 60%;
    }
  }
}

@include media-breakpoint-up(md) {

  .regular-content {
    margin-top: var(--header-height-split-screen);
  }

  body.content header.split {
    align-items: flex-end;
    height: var(--header-height-split-screen);

    .split-screen {
      flex-direction: row;

      &__type {
        flex: 0 0 47vw;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .hgroup {
          /* width: 100%;
          max-width: 735px;
          padding-right: 30px;
          padding-left: 15px;
          padding-top: 4vw; */
          width: 100%;
          max-width: 46vw;
          margin-right: 1vw;
          padding-left: calc(46vw - 76%);
          padding-top: 4vw;
        

          h1 {
            //font-size: 4.0rem;

            span {
              color: $red;
            }
          }
        }
      }

      &__image {
        flex: 1 0 50%;
        //height:25vw;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  body.content header.split {

    .split-screen {

      &__type {

        .hgroup {
          h1 {
            //font-size: 4.7rem;
          }
        }
      }
    }
  }
}

// animated split screen

.split-screen__image-animation-container {
  position: relative;
  height: 100%;
  max-width: 100%;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;


  }
}