// social icons

// in off-canvas and in content

ul#social-nav,
ul.social-nav {
  display:flex;
  flex-direction: row;
  align-items: center;
  //justify-content: flex-end;
  list-style:none;
  margin:0 0 0 -15px;
  padding-left:2em;
  padding-right:0;

  li {
    padding: 0;
    margin: 0 15px;

    a {
      border: 1px solid transparent;
      background-color:rgba(255, 255, 255, 0);
      display: inline-block;
      font: var(--step-1)/1.4 sans-serif;
      //width: 1.8em;
      //height: 2.2em;
      text-decoration: none;
      position: relative;
      text-align: center;
      margin:.0em;

      &:after {
        display:none;
      }

      svg {
        width:1.5em;
        height:1.5em;
      }

      &:hover {
        //background-color:rgba(255, 255, 255, 0.3);
        //border-color: rgba(0, 0, 0, 0.6);
        opacity:.8;
      }
    }

  }
}


nav ul#social-nav {
 li a {
  font-size:var(--step-1);
 }
}
// when in content:

main .social-nav {
  padding:1em 0 2em 0;
  li a {
    color: white;
    font-size:var(--step-0);
  }
}


// Support-nav

nav ul#support-nav {
  padding-left:2em;

  li {
    display:inline-block;
    padding:0 2em 0 0;
    a {
      //font:650 1.0em/1.4 $sans-serif-font;
      font:650 var(--step-0)/1.4 $sans-serif-font;
      color:black;
    }
  }
}