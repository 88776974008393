// Typography
// ================================

html,
body {
  line-height: 1.6;
  font-size: 16px;
  font-family: $sans-serif-font;
  color: $text-color;
  font-style: normal;
  font-weight: 420;
/* 
  @include media-breakpoint-down(md) {
    font-size: 17px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  } */
}

address {
  font-style: normal;
}


h1,
h2,
h3,
h4,
h5,
h6,
ul {
  letter-spacing: -0.018em;
}



a {
  color: black;

  &:hover {
    text-decoration-color: var(--red);
  }
}

h1,
.first-heading {
  //font: 720 2.4rem/1 $sans-serif-font;
  font: 720 var(--step-5)/1 $sans-serif-font;
  padding: 0;
  margin: 0;
  margin-bottom: .4em;
  max-width:17ch;
/* 
  @include media-breakpoint-up(md) {
    font-size: 3.2rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3.8rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 4.5rem;
  } */

  span {
    /* font: 700 2rem/1 $sans-serif-font; */
    font: 700 var(--step-2)/1 $sans-serif-font;
    letter-spacing: -0.02em;
    display: block;
    padding-top: 1.2em;
    padding-bottom:.5em;
    color:$red;

    @include media-breakpoint-down(lg) {}

    @include media-breakpoint-down(sm) {
      //font-size: 1.6rem;
      padding-top: .7em;
    }
  }
}

h3,
.third-heading {
  display: block;
  //font: 500 4.5rem/1.1 $sans-serif-font;
  font: 500 var(--step-5)/1.1 $sans-serif-font;
  padding: 0;
  margin: 0 0 .5em;
/*
  @media (min-width:1680px) {
    font-size: 4.7rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: 2.8rem;
  }

  @include media-breakpoint-down(md) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 2.2rem;
  }*/
}

h2,
.second-heading {
  display: block;
  //font: 740 1.9rem/1.2 $sans-serif-font;
  font: 740 var(--step-2)/1.2 $sans-serif-font;
  padding: 0;
  margin: 0 0 0.9em;
/*
  @include media-breakpoint-down(lg) {
    font-size: 1.6rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.3rem;
    line-height: 1.2;
  } */
}


h4,
blockquote,
.fourth-heading {
  display: block;
  //font: 500 1.2rem/1.2 $sans-serif-font;
  font: 500 var(--step-2)/1.2 $sans-serif-font;
  padding: 0;
  margin: 0 0 0.9em;
/*
  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }*/
}
blockquote footer {
  background: transparent;
  font: 400 var(--step-1)/1.2 $sans-serif-font;
  margin-top: 1em;

  &:before {
    content: "–––– ";
  }
}


h5,
.fifth-heading {
  display: block;
  font: 600 var(--step-1)/1 $sans-serif-font;
  padding: 0;
  margin: 0 0 1em;

  @include media-breakpoint-down(lg) {}

  @include media-breakpoint-down(sm) {
    //font-size: 1.1rem;
  }
}


// h3 wenn h1 eine Dachzeile hat, abrücken (auf die .col anwenden)

.dachzeile-padding {
  padding-top: 1em;

  @include media-breakpoint-up(sm) {}

  @include media-breakpoint-up(md) {
    padding-top: 3.2em;
  }
}

.light-typo {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .fourth-heading,
  .second-heading,
  .third-heading,
  .fourth-heading,
  .fifth-heading,
  p  {
    color:white;
  }
}


ul {
  margin: 0;
  padding: 0.5em 0 2em 1.4em;

  li {
    padding: 0;
    margin: 0.3em 0 0 0;
  }
}

ul.lined {
  margin: 0 0 1.5em 0;
  padding: 0;
  /* font-size: 1em; */
  font-size: var(--step-0);
  line-height: 1.4;
  list-style: none;
  border-top: 1px solid $gray-darker;

  li {
    margin: 0;
    padding: 0.3em 0;
    border-bottom: 1px solid $gray-darker;
    font-weight: 600;
  }

}

dl {
  dt {
    border-top: 1px solid $text-color;
    font-weight: 700;
    padding: .5em 0 0 0;
    margin: .5em 0 0 0;
  }

  dd {
    margin: 0;
    padding: 0;
  }
}

 
.boldred {
  display: block;
  list-style: none;
  text-decoration: none;
  color: var(--red);
  font: 750 2.6em/1.2 $sans-serif-font;

  @include media-breakpoint-down(lg) {
    font-size: 1.7em;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.3em;
  }

  &--medium {
    font: 800 1.9em/1.2 $sans-serif-font;
    list-style: none;
    margin: 1em 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      color: var(--red);
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.7em;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.3em;
    }
  }
}
 
p, ul li {
  margin: 0;
  padding: 0;
  margin-bottom: 1.5em;
  hyphens: auto;
  line-height: 1.2;
  letter-spacing:-.02em;
  //font-size:1.15em;
  font-size:var(--step-0);
  font-weight:480;
}

ul li {
  margin-bottom:1em;
}


.center {
  text-align: center;
  display: block;
}

s {
  color: $gray-dark;
}

.more {
  //font: 600 1em/1 $sans-serif-font;
  font: 600 var(--step-0)/1 $sans-serif-font;
  color: $text-color;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: underline;

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    background: url('/img/arrow.svg') no-repeat;
    background-size: contain;
    height: 1.2em;
    width: 3em;
    font-size:1em;
  }

  &--big {
    font-size: 1.6em;
  }
  &--small {
    font-size: .9em;
    &:before {
      font-size:.75em;
    }
  }
}
.button {
    background:var(--red);
    color:white;
    text-decoration:none;
    /* font-size: 1.1em; */
    font-size: var(--step-0);
    padding: 1em 2.4em;
    text-decoration: none;
    font-weight: 600;

    @include media-breakpoint-up(md){
      /* font-size: 1.3em; */
    }

    &:hover {
      background:var(--red-darker);
    }
}

.big-link {
  font: 400 var(--step-2)/1 $sans-serif-font;
  border-top: 1px solid $gray-darker;
  padding: 1em 0;
  display: block;
  //text-decoration: none;
  .active & {
    color:var(--red);
  }
}

// Button für Alle Projekte auf der Startseite
/* 
.projekte-button {
  display: flex;
  flex-direction: row;
  margin: 6em 0 -6em 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 8em;
    width: 8em;
    border-radius: 50%;
    background: var(--red);
    color: white !important;
    text-decoration: none;
    margin: auto;
    text-align: center;
    font-weight: 800;
    letter-spacing: .05em;
    line-height: 1.3;
    cursor: pointer;
    transform: scale(1);
    transition: transform .2s ease-in-out;
    box-shadow: -3px 0 33px #00000052;

    &:hover {
      transform: scale(1.1);
    }

    @include media-breakpoint-down(sm) {
      font-size: .8em;
    }
  }

  @include media-breakpoint-down(sm) {
    margin: 3em 0 3em 0;
  }
}
 */
.content-icon {
  svg {
    margin: .5em 0 1em;
    width: 4em;
    height: 4.7em;
  }

  p {
    margin: 1em;
  }
}

.inline-icon {
  height: 1.6em;
  width: 1.6em;
  display: inline-block;
  margin-right: .5em;
}



// some special styles for the landingpages

.landing-page-content,
.regular-content-no-header {

  figure {
    margin: 0;
    padding: 1em 0 3em;
    height: auto;
  }

  h3 {
    padding-top: 1em;
  }

  ul {
    @extend .boldred;
    @extend .boldred--medium;
    margin-top: 0;

    li {
      font-size:var(--step-3);
    }
  }
}

// some specials für the "page" content (e.g. impressumg, uses,..)

.page-content {
  padding-top: 10em;

  h1 {
    //@extend .second-heading;
  }

  h2 {
    //@extend .third-heading;
  }

  h3 {
    //@extend .fifth-heading;
  }

}

// lang text (Leistungen)

.long-text {
  h4 {
    @extend .second-heading;
  }
}