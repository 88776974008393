// All the betics CSS animation and start points for gsap

:root {
    --sd-accent-color: #FFE000;
}

.background-swirl {
    background: url('/case-studies/webdesign-sdsugardaddies/img/swirl.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //transform: translateX(-50%);
}

.SD-animation {
    position: relative;
    width: 1600px;
    height: auto;
    background: #FFE000;
    position: relative;
    max-width: 100%;
    aspect-ratio: 16 / 10;


    .sd-schriftzug {
        position: absolute;
        display: block;
        left: 50%;
        width: 70%;
        height: 0;
        padding-bottom: 12%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transform: translateX(-50%);
        background-image: url('/case-studies/webdesign-sdsugardaddies/img/sd-schriftzug.svg');

        &_1 {
            //transform: translateY(-7%);
            top: 10%;
        }

        &_2 {
            //transform: translateY(-6%) translateX(-4%);
            top: 30%;
        }

        &_3 {
            //transform: translateY(-3%);
            top: 50%;
        }

        &_4 {
            //transform: translateY(-3%);
            top: 70%;
        }
    }

    .sd-signet {
        position: absolute;
        left: 50%;
        width: 70%;
        bottom: 20%;
        height: 0;
        padding-bottom: 43%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transform: translateX(-50%);
        background-image: url('/case-studies/webdesign-sdsugardaddies/img/sd-signet.svg');
    }

    .sd-final-schriftzug {
        position: absolute;
        bottom: 35%;
        left: 50%;
        width: 70%;
        height: 0;
        padding-bottom: 12%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transform: translateX(-50%);
        background-image: url('/case-studies/webdesign-sdsugardaddies/img/sd-final-schriftzug.svg');
    }
}

// Policeline Animation


div#police-line--kontakt {
    position: absolute;
    top: 20%;
    overflow-x: hidden;
    //min-height: 17em;
    margin-bottom: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(sm) {
        top: 15%;
    }

    @include media-breakpoint-up(lg) {
        top: 10%;
    }

    #sugar-line__holder--kontakt {
        width: 100%;
        min-height: 35vw;
        height: auto;
        position: relative;

    }

    #sugar-line_1 {
        position: relative;
        left: -20%;

        width: 130vw;
        min-height: 4vw;
    }

    #sugar-line_2 {
        position: relative;
        left: -30%;

        width: 130vw;
        min-height: 4vw;
    }

    #sugar-line_3 {
        position: relative;
        left: -45%;

        width: 130vw;
        min-height: 4vw;
    }

    +.container {
        position: relative;
        z-index: 1;
    }
}

// paralax effect on devices

.parallax-holder {
    position: relative;
    height: auto;

    #dev_1 {
        position: absolute;
        top: 9%;
        left: 33%;
        height: 27%;
        width: 54%;
        //background: #f90;
    }

    #dev_2 {
        position: absolute;
        top: 78%;
        left: 32%;
        height: 33%;
        width: 36%;
        //background: #ff9900;
    }

    #riegel {
        position: absolute;
        top: 49%;
        left: 48%;
        height: 17%;
        width: 43%;
        //background: #ff9900;
    }

}

// the movie section 
.image--movie {
    margin-top: 2em;

    @include media-breakpoint-up(sm) {
        margin-top: 3em;
    }

}

.sd-movie-holder {
    position: relative;

    video {
        position: absolute;
        z-index: 1;
        top: 12.7%;
        left: 8.8%;
        width: 68.9%;
    }

    img {
        position: relative;
        z-index: 2;
    }
}