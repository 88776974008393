// the burger
// ===============================


button#burger {
  position: absolute;
  top:2em;
  right:2em;
  border: none;
  background: transparent;
  display: block;
  margin: 0 2px 0 0;
  outline: none;
  z-index: 12;
  cursor:pointer;
  
  
  @include media-breakpoint-down(sm) {
    top:15px;
    right:15px;
  }

  &.burger-icon {
    margin-bottom: 0;
    height:3.4em;
    width:3.4em;
    transition: background .4s ease-in-out;
    background:fade-out(black, .1);
    border:1px solid fade-out(white, .7);
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @include media-breakpoint-up(md) {
      height:4.0em;
      width:4.0em;
    }
    @include media-breakpoint-up(lg) {
      height:4.3em;
      width:4.3em;
    }

    svg {
      width:1.5em;
      height:1.5em;

      @include media-breakpoint-up(md) {
        width:1.7em;
        height:1.7em;
      }
      @include media-breakpoint-up(md) {
        width:1.5em;
        height:1.5em;
      }
    }

    path {
      transition: all .4s ease-in-out;
    }

    path#burger-upper {
      transform: rotate(0deg);
      transform-origin: 22px 1px;
    }

    path#burger-lower {
      transform: rotate(0deg);
      transform-origin: 23px 16px;
    }

    path#burger-middle {
      opacity: 1;
    }

    &.close-button {
      //background:fade-out(black,.5);
      path#burger-upper {
        transform: translateX(-2px) rotate(-45deg);
        //transform-origin: right top;
      }

      path#burger-lower {
        transform: translateX(-2px) rotate(45deg);
        //transform-origin: right bottom;
      }

      path#burger-middle {
        opacity: 0;
      }
    }
  }
}
