// Le screencast

video {
  width:100%;
  height:auto;
}

.screencast__inner {
  background: url('/img/movie-bg.jpg') no-repeat;
  background-position: center;
  padding: 18% 16% 25%;
  background-size: 96%;
  max-width: 1120px;
  margin: auto;
}

// full width:
@media (min-width: 1680px) {
  .screencast__inner {
    background: url(/img/movie-bg.jpg) no-repeat;
    background-position: center;
    padding: 18% 11% 23.5%;
    background-size: 100%;
    max-width: 1120px;
    margin: auto;
  }
}