footer {
  background: $gray-light;
  position: relative;

  address, p, li a {
    font-size: var(--step-0);
  }

  @include media-breakpoint-down(md) {
    //font-size: 90%;
  }

  ul#social-nav,
  ul.social-nav {
    padding: 0;

    li a {
      color: $red;

      svg {
        width: 1.7em;
        height: 1.7em;
      }
    }
  }

  .footer__upper {
    text-align: center;
    padding-top: 3em;
    padding-bottom: 3em;
    border-bottom: 1px solid $white;

    img,
    svg {
      width: 10em;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 2em;
      padding-bottom: 3em;

      img,
      svg {
        width: 8em;
      }
    }
  }

  .footer__middle {
    padding-top: 2em;
    padding-bottom: 2em;

    .copyright {
      margin-top: 1.8em;
    }

    @include media-breakpoint-up(md) {
      .grid {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        gap: 20px;

        .features {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          @media (min-width: 1680px) {
            flex-direction: row;
          }
        }

        .navigation {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
        }
      }

      .copyright {
        text-align: center;
        margin-top: 0em;
      }
    }

    address {
      line-height: 1.4;

      strong {
        margin-bottom: .7em;
        display: block;
      }
    }

    nav,
    p,
    address {
      margin: 1em 0;
    }

    @include media-breakpoint-down(sm) {
      nav {
        padding: 0 0 1em;
      }

      address {
        margin-top: 0;
        padding: 0 0 1em;
      }
    }
  }

  .features a {
    text-decoration: none;
  }
  .features a span {
    font-weight: 700;
    text-decoration: underline;
    color: $text-color;
  }

  nav ul.support-navigation {
    margin: 0 -15px;
    padding: 0;
    list-style: none;
    @media (min-width:1680px) {
      display: flex;
    }

    li {
      margin: 0;
      padding: 0;

      a {
        color: $text-color;
        text-decoration: none;
        line-height: 1.6;
        display: block;
        padding: 0 15px;

        @include media-breakpoint-down(sm) {
          padding: 0.2em 15px;
        }

        &:hover {
          text-decoration: underline;
          text-decoration-color: var(--red);
        }
      }
    }
  }

  .icon {
    margin: .5em 1em 1em 0;
    width: 3.4em;
    height: 4.4em;
  }
  .icon.icon-kirby-partner {
    width:14em;
  }

  .footer__lower {
    height: 4em;
    background: var(--red) url('/img/footer-lower-img.svg') no-repeat center;
    background-size: cover;
  }

  .icon-heart {
    height: 1em;
    width: 1em;
  }
}

// a bit more space between tap targets
@include media-breakpoint-down(sm) {
  footer address a {
    display: inline-block;
    margin-bottom: .5em;
  }
}