// All the betics CSS animation and start points for gsap

:root {
    --wik-base-color: #2B2B2B;
    --wik-accent-color: #0E6692;
    --wik-accent-color-2: #D43F42;
    --wik-accent-color-3: #8F0E14;
}

.wik-half-background {
    position: relative;
    
    & > div {
        position: relative;
        z-index:2;
    } 
    
    &:after {
        content:"";
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:12em;
        background-color: var(--wik-accent-color-2);
        z-index:1;

        @include media-breakpoint-up(sm) {
            height:15em;
        }
        @include media-breakpoint-up(md) {
            height:20em;
        }

    }
}