
.svg-hide {
  display:none;
}

.cl-client-logos {
  text-align:center;

  .cl-wrapper {


    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;

    > div {
      flex:0 0 33%;
      display: flex;
      color:rgb(49, 49, 49);
      padding:0em .3em;

      svg {
        width: 100%;
        height:auto;
        margin-bottom: 0px;
        max-width:100%;
        max-height:80px;
      }
      @include media-breakpoint-up(sm) {
        flex:0 0 25%;
        padding:0em 1.2em;
       
        svg { 
          max-height:75px;
        }
      }

      @include media-breakpoint-up(md) {
        flex:0 0 25%;
        
        svg { 
          max-height:100px;
        }
      }
      @include media-breakpoint-up(lg) {
        flex:0 0 20%;
        
        svg { 
          max-height:120px;
        }
      }

      
    }
    
  }
}