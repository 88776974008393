// generic imag animation

.img-fade-in-left {
  position: relative;
  transform: translate(0);
  opacity:1;
}


// Styles for the gsap animations

.animation-wrapper {
  margin: 0;
  padding: 0 0 20em !important;

  @include media-breakpoint-down(md) {
    font-size: 75%;
  }
  @include media-breakpoint-down(sm) {
    font-size: 50%;
  }
}
.animation__inner-wrapper {
  transform: rotate(23deg);
}
.animation {
  //width:1000px;
  height:70em;
  position: relative;
  margin:0 auto;
  //transform: rotate(15deg);
}

.bottle {
  position: absolute;
  height:80em;
  width:20em;
  opacity:1;
}
.bottle-1 {
  //background:pink;
  top:8em;
  left:50%;
  transform: rotate(0deg) translateX(00px);
}
.bottle-2 {
  //background:violet;
  top:0em;
  right:50%;
  transform: rotate(0deg) translateX(00px);
}