/* typography */
$sans-serif-font:   'Inter var', sans-serif;
$serif-font:        'Inter var', sans-serif;
$serif-font-bold:   'Inter var', sans-serif;


/* colors */
$white:           #fff;
$black:           #000;
$red:             #FF263C;
//$red: orange;

//$red:             hsl(354, 100, 57);
$brand-color:     $red;

$text-color:      #000;

$gray-light:      #E7E7E3;
$gray:            #A9A99E;
$gray-dark:       #76766E;
$gray-darker:       #474743;


// CSS custom properties (aka css vars)
// way easier to manipulate its lightness

:root {
  --red: #{$red};
  --red-darker: #{darken($red, 10%)};
  --warm-gray: #{$gray-light}; /* is also gray-light */
  --warm-gray-darker: #{$gray}; /* is also gray */
}
