// Le Blog:

.blog {
    header {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: flex-end;
        height: auto;
        margin-top: 12rem;
        width: 100%;
    }
    .regular-content {
        margin-top:0;
    }
}
.grid-blog-post-header {
    @include media-breakpoint-up(lg) {
        display:grid;
        grid-template-columns: 1fr 3fr;
    }
    .blog-header__meta {
        font-weight:500;
        font-size:1.1em;
        line-height:1.2em;
        padding-top:.3em;
        margin-bottom:1em;
     
        span {
            color:var(--red);
        }
    }
}
// Grid Blog overview

// fetaure Grid (3 col)

.blog-overview-grid {
    display: grid;
    border-top:1px solid black;
    padding:2em 0;
    gap: 1.5em;
    text-decoration:none;
  
    &:last-of-type {
        border-bottom:1px solid black;
    }
  
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 2fr 1fr;
      grid-template-rows:max-content;
      
  
      >:nth-child(2) {
        grid-column: 2 / 4;
      }

      >:nth-child(3) {
        grid-column: 2 / 4;
      }
     
    }
    @include media-breakpoint-up(xl) {
      
  
      >:nth-child(2) {
        grid-column: 2 / 3;
      }
      >:nth-child(3) {
        grid-column: 3 / 4;
      }
   
    }
    h2 {
      padding-right:1em;
    }
          
}

// nur auf der overview seite::after

body:not(.blog-post) .blog-overview-grid:first-of-type {
    @include media-breakpoint-up(lg) {
        gap:0 1.5em;
    
        >:nth-child(1) {
            grid-column: 1 / 3;
            grid-row:1 /spam 2;
        }
        >:nth-child(2) {
          grid-column: 3 / 4;
        }
        >:nth-child(3) {
          grid-column: 3 / 4;
        }
    }
    @include media-breakpoint-up(xl) {
      
    
    }
}      
  