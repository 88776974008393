// All the betics CSS animation and start points for gsap

:root {
    --cyberdyne-accent-color: #FA4C4C;
    --cyberdyne-secondary-color: #243242;
    --cyberdyne-third-color: #8095A8;
}

#cyberdyne-animation {
    background: #E6EAEE;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;

    .holder {
        width: 100%;
        height: auto;
        aspect-ratio: 4 / 2.4;
        position: relative;

        &_1,
        &_5,
        &_2,
        &_6 {
            top: -20%;
            left: -15%;
        }

        &_3,
        &_7,
        &_4,
        &_8 {
            top: 0%;
            left: 12%;
        }

    }

    .cyberscreen {

        width: 100%;
        top: 0;
        left: 0;
        position: absolute;

        &_1,
        &_2,
        &_3,
        &_4 {
            top: 0%;
            left: 0%;
        }

        &_5,
        &_6,
        &_7,
        &_8 {
            top: 0%;
            left: 220%;
        }
    }
}