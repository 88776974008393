// Le Case-studies Navigation DESKTOP

$client-nav-pane-width: 33vw;
$content-nav-pane-width: 75vw;
$transform-duration: .4s;
$transform-easing: cubic-bezier(.25, .46, .38, .99);

@include media-breakpoint-up(lg) {
  #client-nav {
    position: fixed;
    z-index: 12;
    top: 0;
    bottom: 0;
    right: -$client-nav-pane-width;
    width: $client-nav-pane-width;
    transition: transform $transform-duration $transform-easing;
    height: 100%;
    overflow-y: auto;

    &.active {
      transform: translateX(-$client-nav-pane-width);
    }
  }

  #main-nav {
    position: fixed;
    z-index: 12;
    top: 0;
    bottom: 0;
    right: -$content-nav-pane-width;
    width: $content-nav-pane-width;
    transition: transform $transform-duration $transform-easing;
    height: 100%;
    overflow-y: auto;

    &.active {
      transform: translateX(-$content-nav-pane-width);
      box-shadow: -3px 0px 33px #00000052;
    }
  }
}

nav {
  h4 {
    font: 750 var(--step-0)/1.4 $sans-serif-font;
    text-align: left;
    display: block;
    color: #fff;
    padding: 3em 0 0 1.8em;
  }
}


#client-nav {
  background: rgba(0, 0, 0, .92);

  ul {
    list-style: none;
    margin: 0;
    padding: 3.2em 0 1em;

    li {
      padding: 0 2em;
      margin: 0;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        background: var(--red);
        transition: width 0.2s ease-out;
      }

      a {
        position: relative;
        z-index: 2;
        display: block;
        padding: .8em 0;
        //font: 900 1.6rem/1.2 $sans-serif-font;
        font: 900 var(--step-1)/1.2 $sans-serif-font;
        color: white;
        text-decoration: none;
        border-bottom: 1px solid rgba(255, 255, 255, .1);

        span {
          //font: 600 0.8rem/1.4 $sans-serif-font;
          font: 600 var(--step--1)/1.4 $sans-serif-font;
          display: block;
          color:var(--warm-gray-darker);
        }
      }

      &.active {
        background: #333;
      }

      &:hover {
        span {
          color:white;
        }
        &:after {
          width: 100%;
        }
      }

      &:last-child a {
        border-bottom: 0;
      }
    }
  }
}

// second pane

#main-nav {
  background: var(--red);
  background: var(--warm-gray);

  h4 {
    color:black;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 3.2em 0 1em;

    // new element:smooth opening of the navi via css grid.

    .ul-wrapper {
      position: relative;
      display:grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 0.3s ease-out;

      li a,
      li strong {
        font-size: var(--step-1);
      }
    }


    // second level not visible (for the moment)
    // is wrapperd with a div for smooth opening and closing
    ul {
      display: block;
      margin: 0;
      padding: 0;
      overflow:hidden;

      li {
        a, strong {
        //font-size: .85em;
        font-size: var(--step-1);
      }
    }
    }

    li {
      padding: 0 1.6em;
      margin: 0;
      position: relative;

      li {
        padding:0 1.1em;
      }

      a,
      strong {
        display: inline-block;
        padding: .2em 0;
        margin: .2em 0;
        //font: 900 2.2em/1.2 $sans-serif-font;
        font: 900 var(--step-3)/1.2 $sans-serif-font;
        color: var(--red);
        text-decoration: none;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 3px;
          background: var(--red);
          display: block;
          transition: all .2s ease-out;
        }

        span {
          font: 600 0.7rem/1.4 $sans-serif-font;
          display: block;
        }
      }

      // Toggle for subnav
      .show-hide-children {
        font-size:var(--step-2);
        display: inline-flex;
        color: var(--red);
        height: 1em;
        width: 1.3em;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 7px;
        top: .1em;

        svg {
          height: .9em;
          width: .9em;
          transition: all .3s ease-in-out;
        }
      }

      strong,
      &.open>a,
      &:hover>a,
      &.hover {
        &:after {
          width: 100%;
          left: 0;
        }
      }

      strong,
      &.open,
      &:hover>a,
      &.hover {

        &.open {
          .show-hide-children svg {
            transform: rotate(90deg);
          }

          // wrapper for smooth opening and closing
          .ul-wrapper {
            //display: block;
            position:relative;
            grid-template-rows: 1fr;
          }

        }

        &.hover {
          .show-hide-children svg {
            transform: rotate(90deg);
          }
          
          // wrapper for smooth opening and closing
          .ul-wrapper {
            //display: block;
            position:relative;
            grid-template-rows: 1fr;
          }
        }
      }
    }
  }
}

// Le navigation MOBILE

body.nav-open {
  overflow: hidden;
}

@include media-breakpoint-down(md) {

  .sticky-nav .logo {
    transition: all ease-in-out .3s;
    opacity: 1;
    transform: translateX(0);
  }

  .nav-open .sticky-nav .logo {
    transform: translateX(-400px);
    opacity: 0;
  }

  aside#mobile-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 12;
    transform: translateX(100%);
    transition: transform $transform-duration $transform-easing;

    &.active {
      transform: translateX(0);
    }

    #main-nav {
      position: relative;
      flex: 0 0 auto;
      overflow: auto;
      font-size: 70%;
      padding: 1.2rem 0 0.5rem;

      ul {
        padding: 1rem 0 1rem 4.1rem;

        ul {
          padding: 0.2rem 0 0.2rem 0.5rem;
        }
      }
    }

    #social-nav,
    #support-nav {
      display: none;
    }

    #client-nav {
      position: relative;
      flex: 1 1 auto;
      overflow: auto;
      padding-top: 1em;


      ul {
        padding: 0.4rem 0 6rem 4rem;

        li {
          padding: 0 2rem;

          a {
            //font-size: 1.3em;
          }
        }
      }
    }
  }

  nav h4 {
    position: fixed;
    font-size: 1rem;
    text-align: right;
    transform: rotate(-90deg);
    transform-origin: 64px 34px;
    padding: 0;
  }
}