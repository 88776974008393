.ref-content .teaser {
  margin-bottom:0;
  padding-bottom:6em;
  padding-top:6em;

}
.teaser {
  margin-bottom:0;
  padding-bottom:6em;
  padding-top:6em;

  h3 {
    margin-bottom:.3em;
    padding-top:3em;
  }
  .teaser__inner > h3 {
    padding-top: 1em;
    margin-bottom: 3em;
  }
  .teaser__headline-link {
    display:flex;
    justify-content: space-between;
    align-items: last baseline;

    a.more {
      margin-bottom:1em;
      margin-left: 1em;
      white-space: nowrap;
    }
  }
  a {
    text-decoration:none;
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--red);
    }
  }
  figure {
    margin:0 0 1em 0;

    .teaser-box__image {
      position: relative;
      object-fit: cover;
    }

    figcaption {
      padding:1em 0 .5em 0;
    }

    h5, h4, .fifth-heading, p {
      margin-bottom: 0;
      font:500 1rem/1.2 $sans-serif-font;
      font:500 var(--step-0)/1.2 $sans-serif-font;
    }

    h3 {
      font-size:1.4em;
      padding:0;
    }

    @include media-breakpoint-down(sm) {
      h3 {
        font-size:.7em;
      }
    }
  }
  a .teaser-box__image {
    filter: grayscale(1);
    transition: filter .3s;
  }
  a:hover .teaser-box__image  {
    filter: none;
  }
}
