// Kathrin & Michael

.people-container {
  background: url('../img/ampersand.svg') no-repeat;
  background-position: center;

}

.people {
  padding:0 0 0;

  .row:first-child {
    padding-bottom:6em;
  }
  .image-wrapper {
    display:flex;

    .image-holder {
      width:80%;
    }
    img {
      width:100%;
      height:auto;
      max-width:100%;
      border-radius:50%;
    }
  }
  &__kathrin {
    .image-wrapper {
      position: relative;
      margin-top:-10em;
    }
  }
  &__michael {
    .row:first-child {
      flex-direction:row-reverse;
      padding-bottom:0em;
    }
    .image-wrapper {
      justify-content: flex-end;
    }
  }
}

@include media-breakpoint-down(sm) {
  .people {
    padding:2em 0 1em;

    .row:first-child {
      padding-bottom: 0em;
    }
    &__michael .image-wrapper,
    .image-wrapper {
      justify-content: center;
      margin-bottom:2em;
    }
    &__michael .row:first-child {
      padding-bottom: 0em;
    }
  }
}