// Le Portfolio header , the case-studies main site


$header-height: 85vh;
$header-offset: 5em;

.portfolio-content {
  margin-top:calc(#{$header-height} - #{$header-offset});
  top:0;
  position: relative;
  background:$white;
  z-index: 1;

  > section:first-child {
    padding-top:5em;

    @include media-breakpoint-down(sm) {
      padding-top:3em;
      padding-bottom:2em;
    }
  }
}

header.portfolio {
  position: fixed;
  top:0;
  background-position: center;
  background-size:cover;
  background-repeat:no-repeat;
  background-color: var(--warm-gray);

  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: center;
  height:calc(#{$header-height} - #{$header-offset});
  width:100%;


  img {
    flex:1 1;
    max-width:1440px;
    height:auto;
    

    padding:12vh 0 5vh;

    @media (max-width:1440px) {
      flex:1 1;
      max-width:90vw;
      //padding-top:calc(10vh + 10em);
    }
    &.hollow {
      position:absolute;
    }
  }

}
