// All the betics CSS animation and start points for gsap

:root {
    --hyc-accent-color: #FFE000;
}

.hc-icon-grid {
    display:grid;
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(6, 1fr);
    }
}

@include media-breakpoint-down(sm) {
    .gc-helix-icon {
        display:block;
        width:50%;
        margin:auto;
    }
}

blockquote.hyc-quote {
    --turquise-darker: #8FE0CC;
    --blue-darker:#0068B4;

    display: inline-block;
    color: transparent;
    background: #000;
    background: linear-gradient(90deg,var(--turquise-darker) 10%,var(--blue-darker) 100%);
    background-clip: border-box;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none !important;
}


// Masthead
// Animation on Startpage

header.hyc {
    --turquise-darker: #0265AE;
    --blue-darker:#212B36;
    //background-image: url('/img/illu-start-header@0,5x.png');
    background-position: center center;
    background-size: cover;
    background: linear-gradient(130deg,var(--turquise-darker) 10%,var(--blue-darker) 70%);
    background-clip: border-box;
    background-repeat: no-repeat;


    .hyc__image-container {
        position: relative;
        height: 100%;
        width:100%;
    }

    svg#animated {
        width: auto;
        height: 80vh;
        position: absolute;
        top: 50%;
        transform: skewY(-10deg) translateY(-50%) translateX(-50%) scale(2);

        @include media-breakpoint-up(xs) {
            transform: skewY(-10deg) translateY(-50%) translateX(0) scale(1);
        }
        @include media-breakpoint-up(lg) {
            width: 100%;
            height: auto;
        }

        // this is the positioning guide within the svg
        #Rectangle {
            display: none;
        }
    }

    .hyc__inner-container {
        position: relative;
        z-index: 2;
        text-align: center;
        min-height: 95vh;
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }
}


.hyc-movie-holder {
    position: relative;

    video {
        position: absolute;
        z-index: 1;
        top: 34.3%;
        left: 28vw;
        width: 13vw;
    }

    img {
        position: relative;
        z-index: 2;
    }
}