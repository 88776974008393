//the image with markers

.kathrin-und-michael {
    position: relative;

    .image-marker {
        position: absolute;
        height:10vw;
        width:10vw;

        @include media-breakpoint-up(md) {
            height:7vw;
            width:7vw;
        }

        &--michael {
            top:24%;
            left:26%;
        }
        &--kathrin {
            top:54%;
            left:72%;
        }
    }
    .marker-icon {
        height:100%;
        width:100%;
    }
    
}

// Prozess Items Grid (is full-bleedg grid)

.prozess {

    .full-bleed-grid {
        //align-items: center;
        margin-bottom:3em;
    }
    .prozess__number {
        grid-column: 7 / 9;
        grid-row: span 2;
        position: relative;
        text-align:center;
        display: flex;
        justify-content: center;
        
        @include media-breakpoint-up(md) {
            display:block;
        }

        img {
            display: block;    
            background:white;
            padding:2em 0;
            position: relative;
            z-index:2;
            max-width:9em;
            margin-top:12em;

            @include media-breakpoint-up(md) {
                max-width:none;
                margin-top:0;
            }
        }
        &:after {
            content:"";
            position: absolute;
            border-left:4px dotted var(--red);
            height:auto;
            top:3.3em;
            bottom:0em;
            left:calc(50% - 2px);
            z-index:1;
        }
    }
    // no line after last number
    @include media-breakpoint-down(md) {
        .full-bleed-grid:first-of-type .prozess__number{
            &:after {
                display:none;
            }
            img {
                margin-top:0;
            }
        }
    }
    // no line after last number
    @include media-breakpoint-up(md) {
        .full-bleed-grid:last-of-type .prozess__number:after {
            display:none;
        }
    }
    .prozess__image {
        grid-column: 9 / 14;
        grid-row: 1
    }
    .prozess__text {
        grid-column: 9 / 14;
        grid-row: 2
    }
    
    .prozess-grid-reverse {
    
        .prozess__image {
            grid-column: 2 / 7;
        }
        .prozess__text {
            grid-column: 2 / 7;
        }   
    }
    h3 span {
        color:var(--red);
    }
}