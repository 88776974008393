// Ausnahmen für dei Startseite

.home {
  header {
    height: auto;
    display: block;
    //background-color: #000;
  }

  .regular-content {
    margin-top: 0;

    @include media-breakpoint-down(md) {
      //margin-top: 80vh;
    }
  }
}

// slider anpassungen:
.start-slider {
  .slick-slide {
    .slide__wrapper {
      position: relative;
      height: 100vh;
      display: flex;
      width: 100%;

      @include media-breakpoint-down(md) {
        height: 80vh;
      }

      .slide__image {
        width: 100%;
        height: 100%;
        display: block;

        img,
        picture {
          object-fit: cover;
          object-position: center;
          display: block;
          position: relative;
          max-width: none;
          padding: 0;
          height: 100%;
          width: inherit;
        }
      }

      .slide__content {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        @extend %gradient-transparent-black;

        .slide__text {
          margin: 2em 2.4em 5em;
          display: flex;
          max-width: 40em;
          align-items: flex-end;

          @include media-breakpoint-down(md) {
            margin: 2em 15px 3em;
            flex-direction: column;
            align-items: flex-start;
          }

          &__content {
            flex: 1 0 55%;
            color: white;

            p {
              margin-bottom: 0;
            }

            p.pagination {
              font-weight: 700;
              margin-bottom: 2em;
            }

            h3,
            .third-heading,
            h3,
            .second-heading {
              margin-bottom: .5em;
            }
          }

          &__link {
            flex: 0 0 54%;
            justify-content: end;
            display: flex;

            a.more {
              color: white;

              @include media-breakpoint-down(md) {
                margin-top: 1em;
                margin-bottom: 1em;
              }
            }
          }
        }

        &--light {
          background: transparent;

          .slide__text__content,
          .slide__text__link a.more {
            color: black;
          }
        }
      }
    }
  }

  .slick-dots {
    bottom: 2em;
    margin: 0 0 0 2em;
    text-align: left;

    @include media-breakpoint-down(md) {
      margin-left: 10px;
      bottom: 15px;
    }
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.invisible-mobile {
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.ref-einleitung {
  grid-column: 2 / 11;
  margin-bottom:4em;
}

.einleitung-client-logos {
  grid-column: 5 / 13;
}

// animation dumbells

// animated split screen


.services {

  &__image {
    grid-column: 4 / 12;
    height:100%;
    width:100%;
    min-height:50vw;
    aspect-ratio: 1 / 1;
  }
  &__image-animation-container {
    position: relative;
    height: 100%;
    max-width: 100%;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
    }
  }
  &__headline {
    grid-column: 4 /12;
    text-align: center;
    margin-top:3em;
  }
  &__services {
    grid-column: 2 /14;
    margin-bottom:3em;
  }
  &__item {
    .big-link {
      text-align: center;
      text-decoration-color: transparent;

      &:hover {
        text-decoration-color: var(--red);
      }
    }

  }
  &__link {
    grid-column: 2 /14;
    display:flex;
    justify-content: center;
    margin-bottom:6em;
  }
}


.agentur {
  background-color: var(--red);

  &__image {
    grid-column: 1 / 8;
    height:100%;
    //width:100%;
    //min-height:50vw;
    aspect-ratio: 1 / 1;
    margin-left: -15px;
    margin-right:-15px;
  }
  &__image-animation-container {
    position: relative;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    background:var(--warm-gray);
    

    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
    }
  }
  &__text {
    grid-column: 9 / 14;
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding:3em 0;

    h2 {
      color:white;
    }
    a {
      color:white;

      &:before {
        filter: brightness(0);
      }
    }
  }
}


.about {

  &__image {
    grid-column: 4 / 12;
    height:100%;
    width:100%;
    //min-height:50vw;
    aspect-ratio: 1 / 1;
  }
  &__image-animation-container {
    position: relative;
    height: 100%;
    max-width: 100%;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
    }
  }
  &__headline {
    grid-column: 4 /12;

  }
  &__subheadline {
    grid-column: 4 /12;
    margin-bottom:3em;
    h2 {
      text-align:center;
    }
  }
  &__link {
    grid-column: 2 /14;
    display:flex;
    justify-content: center;
  }
}


.half-dark {
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 55%, black 55%, black 100%);
}

.home .full-bleed-grid {
  max-width:none;
}

// hero
.first-big-hero-item {
  grid-column: 1 / -1;

  @include media-breakpoint-down(md) {
    img {
      aspect-ratio: 12/16;
      object-fit: cover;
    }
  }
}

// big typo atop 

.headline {
  //min-height:90vh;
  grid-column: 1 / -1;
  padding-top:clamp(8rem,17vw,14rem);
  
  h1 {
    /* font: 450 clamp(22px, 5.5vw, 5.7rem)/0.95 $sans-serif-font; */
    font: 450 var(--step-6)/0.95 $sans-serif-font;
    letter-spacing: -0.048em;
    margin: 0;
    padding: 0;
    max-width: 23.5ch;
    line-height: 1.05;
    a {
      text-decoration-color: var(--red);
    }
  }    
  
}

.hero {
  position: relative;
  z-index:1;
}
.home .section-ref-overview {
  padding-top:28em;
  margin-top:-28em;
  z-index:2;
}


.case-studies-link {
  display:flex;
  justify-content: center;
  margin-top:4em;
  margin-bottom:6em;
}