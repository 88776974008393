// Le Case Studies

/* $header-height: 85vh;
$header-offset: 5em;
 */
$header-height: 100vh;
$header-offset: 0.1px;


.ref-content {
  margin-top:calc(#{$header-height} - #{$header-offset});
  top: 0;
  position: relative;
  background: $white;
  z-index: 1;

  >section:first-child {
    padding-top: 5em;

    @include media-breakpoint-down(sm) {
      padding-top: 3em;
    }
  }

  section {
    padding: 2em 0;

    @include media-breakpoint-down(sm) {
      padding: 1em 0;
    }
  }
}

header {
  position: fixed;
  //margin-top:-10em;
  //margin-bottom:5em;
  top: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height:calc(#{$header-height} - #{$header-offset});
  width: 100%;

  img {
    flex: 1 1;
    max-width: 1440px;
    height: auto;

    padding: 10vh 0;

    @media (max-width:1440px) {
      flex: 1 1;
      max-width: 90vw;
      //padding-top:calc(10vh + 10em);
    }
  }

  &.true-fruits {
    img {
      flex: 1 1;
      max-width: 1440px;
      height: auto;
      padding: 10vh 0;

      @media (min-width:768px) and (max-width:1440px) {
        flex: 1 1;
        max-width: 90vw;
        //padding-top:calc(10vh + 10em);
      }
    }

  }

  &.hyc {
    img {
      flex: 1 1;
      max-width: 400px;
      height: auto;
      padding: 23vh 0;

      @media (max-width:768px) {
        flex: 1 1;
        max-width: 260px;
        //padding-top:calc(10vh + 10em);
      }
    }
  }

  &.cba {
    img {
      flex: 1 1;
      max-width: 800px;
      height: auto;
      padding: 23vh 0 26vh;

      @media (max-width:768px) {
        flex: 1 1;
        max-width: 400px;
        //padding-top:calc(10vh + 10em);
      }
    }
  }

  &.dlr {
    img {
      flex: 1 1;
      max-width: 280px;
      height: auto;
      padding: 23vh 0;

      @media (max-width:768px) {
        flex: 1 1;
        max-width: 200px;
        padding: 0 1em;
        //padding-top:calc(10vh + 10em);
      }
    }
  }

  &.ft {
    img {
      flex: 1 1;
      max-width: 260px;
      height: auto;
      padding: 23vh 0;

      @media (max-width:768px) {
        flex: 1 1;
        max-width: 260px;
        padding: 0 3em;
        //padding-top:calc(10vh + 10em);
      }
    }
  }

  &._3con {
    img {
      flex: 1 1;
      max-width: 400px;
      height: auto;
      padding: 23vh 0;

      @media (max-width:768px) {
        flex: 1 1;
        max-width: 280px;
        padding: 0 1em;
        //padding-top:calc(10vh + 10em);
      }
    }
  }

  &.dmfv {
    img {
      flex: 1 1;
      max-width: 280px;
      padding: 0 1em;
      //padding-top:calc(10vh + 10em);
      flex: 1 1;

      @media (min-width:769px) {
        max-width: 400px;
        height: auto;
        padding: 23vh 0;
      }

      @media (min-width:1280px) {
        max-width: 500px;
        height: auto;
        padding: 23vh 0;
      }
    }
  }
}

.cba-intro {
  p {
    color: white;
  }
}

.cba-logo-wrapper {
  padding: 2em 0 2em;

  @include media-breakpoint-down(sm) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.cba-dreieck {
  padding-left: 3em;
  padding-right: 3em;

  @include media-breakpoint-down(sm) {
    max-width: 280px;
  }
}

.hyc-diagonale {
  position: relative;

  >div {
    position: relative;
    z-index: 10;
  }

  &:before {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 12em;
    background: #0068B4;
    width: 100%;
    transform: skewY(-10deg);
  }

  &:after {
    z-index: 1;
    position: absolute;
    bottom: 1em;
    left: 0;
    content: "";
    height: 12em;
    background: #F7AC19;
    width: 100%;
    transform: skewY(-10deg);
  }

  @include media-breakpoint-up(sm) {
    &:before {
      top: 0;
      height: 25em;
    }

    &:after {
      bottom: 10em;
      height: 25em;
    }
  }
}

.hyc-diagonale-2 {
  position: relative;

  >div {
    position: relative;
    z-index: 10;
  }

  &:after {
    z-index: 1;
    position: absolute;
    bottom: 30%;
    left: 0;
    content: "";
    height: 12em;
    background: #E6E6E6;
    width: 100%;
    transform: skewY(-10deg);
  }

  @include media-breakpoint-up(sm) {
    &:after {
      height: 25em;
    }
  }
}


.dlr-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#013950+0,01878d+100 */
  background: rgb(1, 57, 80);
  /* Old browsers */
  background: -moz-linear-gradient(left, rgba(1, 57, 80, 1) 0%, rgba(1, 135, 141, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(1, 57, 80, 1) 0%, rgba(1, 135, 141, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(1, 57, 80, 1) 0%, rgba(1, 135, 141, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#013950', endColorstr='#01878d', GradientType=1);
  /* IE6-9 */
}

.dmfv-gradient {
  background: rgb(0, 51, 102);
  background: -moz-linear-gradient(90deg, rgba(0, 51, 102, 1) 10%, rgba(0, 153, 204, 1) 80%);
  background: -webkit-linear-gradient(90deg, rgba(0, 51, 102, 1) 10%, rgba(0, 153, 204, 1) 80%);
  background: linear-gradient(90deg, rgba(0, 51, 102, 1) 10%, rgba(0, 153, 204, 1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003366", endColorstr="#0099cc", GradientType=1);
}


.info-box {
  color: $white;

  dt {
    border-color: $white;
  }

  a {
    color: $white;
  }

  &__text {
    padding: 2.2em 2.8em;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

  }

  &__image {
    display: flex;
    height: 100%;
    min-height: 15em;
    flex-direction: row;


    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    img {
      min-height: 100%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &--revertlayer img {
      width: revert-layer;
    }
  }

  &--positiv {
    color: black;

    dt {
      border-color: black;
    }

    a {
      color: black;
    }
  }
}


// specail TF: the splash

.bg-splash {
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 775px;

  @include media-breakpoint-up(md) {
    background-size: 775px;

  }

  @include media-breakpoint-up(lg) {
    background-size: 1550px;

  }
}


// special in dlr: pulled box mobile 
@include media-breakpoint-down(sm) {
  article section.dlr-special-mobile {
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
}

// fabulous specials

.grid {
  display: grid;
  grid-gap: 20px;

  @include media-breakpoint-down(md) {
    grid-gap: 15px;
  }

  @include media-breakpoint-down(sm) {
    grid-gap: 10px;
  }

  @include media-breakpoint-down(xs) {
    grid-gap: 5px;
  }

  &.grid--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.grid--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.grid--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &.grid--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &.grid--12 {
    grid-template-columns: repeat(12, 1fr);
  }

  &.grid--7 {
    grid-template-columns: repeat(7, 1fr);
  }
}

.grid-fab-upper {
  grid-template-rows: repeat(4, auto);

  div {
    &:nth-child(1) {
      grid-column: 1/4;
      grid-row: 1/3;
    }

    &:nth-child(2) {
      grid-column: 2/4;
      grid-row: 3/5;
    }

    &:nth-child(3) {
      grid-column: 4/-1;
      grid-row: 2/4;
    }

    &:nth-child(4) {
      grid-column: 2 / 5;
      grid-row: 3 / 6;
    }
  }

  @include media-breakpoint-down(sm) {
    display: block;

    div {
      margin-bottom: 15px;
    }
  }
}

.grid-fab-lower {
  grid-template-rows: repeat(4, auto);

  div {
    &:nth-child(1) {
      grid-column: 1 / 3;
      grid-row: 1 / 6;
    }

    &:nth-child(2) {
      grid-column: 3 / 7;
      grid-row: 1 / 4;
    }

    &:nth-child(3) {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
      align-items: flex-end;
      //display: flex;
    }

    &:nth-child(4) {
      grid-column: 1 / 3;
      grid-row: 4 / 5;
    }

    &:nth-child(5) {
      grid-column: 3 / 6;
      grid-row: 4 / 5;
    }
  }

  @include media-breakpoint-down(sm) {
    display: block;

    div {
      margin-bottom: 15px;
    }
  }
}

.grid-dzne {
  grid-template-rows: repeat(4, auto);

  div {
    &:nth-child(1) {
      grid-column: 1 / 5;
      grid-row: 1 / 6;
    }

    &:nth-child(2) {
      grid-column: 1 / 6;
      grid-row: 3 / 4;
      align-items: flex-end;
      display: flex;
    }

    &:nth-child(3) {
      grid-column: 6 / 13;
      grid-row: 1 / 4;
    }

    &:nth-child(4) {
      grid-column: 1 / 9;
      grid-row: 4 / 5;
    }

    &:nth-child(5) {
      grid-column: 9 / 13;
      grid-row: 4 / 5;
    }
  }
}

.grid-fab-leafes {
  grid-template-rows: repeat(3, auto);
  align-items: center;

  div {
    &:nth-child(1) {
      grid-column: 1 / 3;
    }
  }
}

// PET Grid

.grid-pet-upper {
  grid-template-rows: repeat(8, auto);

  div {
    &:nth-child(1) {
      grid-column: 1 / 4;
      grid-row: 1 / 5;
    }

    &:nth-child(2) {
      grid-column: 4 / 6;
      grid-row: 3 / 8;
    }
  }

  @include media-breakpoint-down(sm) {
    div {
      &:nth-child(1) {
        grid-column: 1/3;
        grid-row: 1/5;
      }

      &:nth-child(2) {
        grid-column: 2 / 6;
        grid-row: 5 / 9;
      }
    }
  }
}

.grid-pet-lower {
  grid-template-rows: repeat(8, auto);
  padding: 0 2em;

  div {
    &:nth-child(1) {
      grid-column: 3/7;
      grid-row: 1/5;
    }

    &:nth-child(2) {
      grid-column: 1 / 4;
      grid-row: 4 / 8;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 0;

    div {
      &:nth-child(1) {
        grid-column: 3/7;
        grid-row: 1/5;
      }

      &:nth-child(2) {
        grid-column: 1/5;
        grid-row: 5/8;
      }
    }
  }
}




.dmfv-grid {
  grid-gap: 5em;
  padding-top: 3em;
  padding-bottom: 3em;

  div {
    &:nth-child(1) {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }

    &:nth-child(2) {
      display: flex;
    }

    &:nth-child(3) {
      grid-column: 2 / 3;
      align-content: flex-end;
      display: grid;
    }
  }
}

@include media-breakpoint-down(sm) {
  .grid.grid--2 {
    grid-template-columns: repeat(1, 100%);

  }

  .grid.grid--5 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-dzne {
    display: flex;
    flex-direction: column;
    //padding: 2em 20px;

    div {
      margin-bottom: 10px;
    }
  }


  .dmfv-grid {
    display: flex;
    flex-direction: column;
    padding: 2em 20px;

    div:nth-child(2) {
      padding: 2em 0 2em;
    }
  }

  .grid-fab-leafes {

    div {
      &:nth-child(1) {
        grid-column: 1 / 4;
      }
    }
  }
}

@media (min-width: 1680px) {
  .dmfv-grid {
    padding-top: 5em;
    padding-bottom: 5em;
  }
}

// PET Petersberg

.pet-video {
  padding-top: 4em;
  padding-bottom: 4em;
  background: black;

  .intro {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    background-size: 176%;


    @media (min-width:1320px) {
      background-size: auto;
    }
  }

  .intro video {
    height: 100%;
    width: 100%;
    max-width: 1320px;
    padding: 20px;
    object-fit: contain;
  }
}