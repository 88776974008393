// grid style element scaffolding
// the idea is to get rid of the BS-grid altogether

.grid-right-left {

  .right, .left {
    padding:2em 0;
    
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap:1.5em;
    }
  }
  .right {
    >* {
      grid-column: 4 / -2;
    }
    h3 {
      grid-column: 4 / -1;
    }
  }
  .left {
    >* {
      grid-column: 1 / 9;
    }
    h3 {
      grid-column: 1 / 10;
    }
  }
}

// new full-bleed-grid 

.full-bleed-grid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  
  .text-item {
    padding-top:2em;
    grid-column: 2 / 5;
    grid-row: 1;
  }
  
  .full-bleed {  
    grid-column: 6 / -1;
    grid-row: 1;

    &> * {
      display:block;
    } 
    &> * + * {
      margin-top:2em;
    } 
  }

  &--reverse {
    .text-item {
      grid-column: 11 / 14;
      grid-row: 1;
    }
    .full-bleed {
      grid-column: 1 / 10;
      grid-row: 1;
    }
  }
  /*
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  */
  //  @media (min-width: 576px)
  @include media-breakpoint-up(md) {

    /* max-width: 540px; */
  
  //@media (min-width: 768px)
  //@include media-breakpoint-up(md) {
    max-width:none;
    //max-width: 720px;
    padding-right: 0;
    padding-left: 0;
  
    display: grid;
    gap:1.5em;
    grid-template-columns:
      /* minmax( 15px, calc( 50vw - 22rem ))
      repeat( 12, 1fr )
      minmax( 15px, calc( 50vw - 22rem )); */
      minmax( 15px, calc( 50vw - 40% ))
      repeat( 12, 1fr )
      minmax( 15px, calc( 50vw - 40% ));
  }
  //}
  
  /*
  //@media (min-width: 992px)
  @include media-breakpoint-up(lg) {
    //max-width: 960px;
    grid-template-columns:
    minmax( 15px, calc( 50vw - 27rem ))
    repeat( 12, 1fr )
    minmax( 15px, calc( 50vw - 27rem ));
  }
  //@media (min-width: 1200px)
  @include media-breakpoint-up(xl) {
    //max-width: 1140px;
    grid-template-columns:
    minmax( 15px, calc( 50vw - 32rem ))
    repeat( 12, 1fr )
    minmax( 15px, calc( 50vw - 32rem ));
  }
  @media (min-width: 1680px) {
    grid-template-columns:
    minmax( 15px, calc( 50vw - 42rem ))
    repeat( 12, 1fr )
    minmax( 15px, calc( 50vw - 42rem ));
  } */
}

.container {
  max-width:none !important;
  //max-width: 720px;
  @include media-breakpoint-up(md) {
    padding-right: 0;
    padding-left: 0;
    display: grid;
    gap:1.5em;
    grid-template-columns:
      /* minmax( 15px, calc( 50vw - 22rem ))
      repeat( 12, 1fr )
      minmax( 15px, calc( 50vw - 22rem )); */
      minmax( 15px, calc( 50vw - 40% ))
      repeat( 1, 1fr )
      minmax( 15px, calc( 50vw - 40% ));

      >* {
        grid-column: 2/-2;
      }
    }
}

// fetaure Grid (3 col)

.feature-grid {
  display: grid;
  border-top:1px solid black;
  padding:2em 0;

  &:first-of-type {
    border-top:none;
  }
  .ref-content & {
    border-top:none;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 2fr 2fr;
    gap:0 1.5em;

    >:nth-child(3) {
      grid-column: 2 / 3;
    }
    .second-third-col {
      grid-column: 2 / 4;
    }
    &.feature-grid--services {
      row-gap:0;
      
      >:nth-child(2) {
        grid-column: 2 / 3;
      }
      >:nth-child(3) {
        grid-column: 2 / 3;
      }
      >div:nth-child(4) {
        grid-column: 2 / 3;
      }
      >div:nth-child(5) {
        grid-column: 2 / 3;
      }
      >div:nth-child(6) {
        grid-column: 2 / 3;
      }
      .second-third-col {
        padding-top:2em;
      }
    }
    &.feature-grid--services-overview {
      row-gap:0;
      grid-template-columns: 1fr 1fr 2fr;
      
      >:nth-child(2) {
        grid-column: 2 / 3;
        border-top:1px solid $gray-darker;
        padding-top:1em;
      }
      >:nth-child(3) {
        grid-column: 3 / 4;
        border-top:1px solid $gray-darker;
        padding-top:1.5em;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: 2fr 1fr 1fr;
    gap:1.5em;

    >:nth-child(3) {
      grid-column: 3 / 4;
    }
    .second-third-col {
      grid-column: 2 / 4;
    }
    &.feature-grid--services {
      row-gap:0;
      
      >:nth-child(2) {
        grid-column: 2 / 3;
      }
      >:nth-child(3) {
        grid-column: 3 / 4;
      }
      >div:nth-child(4) {
        grid-column: 2 / 3;
      }
      >div:nth-child(5) {
        grid-column: 3 / 4;
      }
      .second-third-col {
        padding-top:2em;
      }
    }
    &.feature-grid--services-overview {
      grid-template-columns: 2fr 1fr 1fr;
      row-gap:0;
      
      >:nth-child(2) {
        grid-column: 2 / 3;
        border-top:1px solid $gray-darker;
        padding-top:1em;
      }
      >:nth-child(3) {
        grid-column: 3 / 4;
        border-top:1px solid $gray-darker;
        padding-top:1.5em;
      }
    }
  }
  .fg-leistugen & {
    // border-top:2px solid #fff;
  }

  
  h2 {
    padding-right:1em;
  }
  ul {
    margin-top:0;
    padding-top:0;
    li {
      margin: 0 0 .5em 0;
    }
  }
  .image-holder {
    margin-bottom:1em;
   
    img {
      width: 100%;
      height: auto;
      max-width: 7em;
      border-radius: 50%;
    }
  }
}
 
.grid-landingpage {
  // currently this is also the grid for the "pages" e.g. impressum, uses,...
  display: grid;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(12, 1fr);

    >* {
      grid-column: 3 / 11;
    }

    h2 {
      grid-column: 3 / 11;
    }

    figure {
      grid-column: 2 / 12;
    }

    .einzug {
      grid-column: 7 / -1;
      padding-top: 3em;
    }
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(12, 1fr);

    >* {
      grid-column: 4 / 10;
    }

    h2 {
      grid-column: 4 / 11;
    }

    figure {
      grid-column: 3 / 11;
    }
  }
}





// new full-bleed-grid 
/* 
.full-bleed-grid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  
  .text-item {
    padding-top:2em;
    grid-column: 2 / 5;
    grid-row: 1;
  }
  
  .full-bleed {  
    grid-column: 6 / -1;
    grid-row: 1;

    &> * {
      display:block;
    } 
    &> * + * {
      margin-top:2em;
    } 
  }

  &--reverse {
    .text-item {
      grid-column: 11 / 14;
      grid-row: 1;
    }
    .full-bleed {
      grid-column: 1 / 10;
      grid-row: 1;
    }
  }
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  //  @media (min-width: 576px)
  @include media-breakpoint-up(sm) {

    max-width: 540px;
  }
  //@media (min-width: 768px)
  @include media-breakpoint-up(md) {
    max-width:none;
    //max-width: 720px;
    padding-right: 0;
    padding-left: 0;
  
    display: grid;
    gap:1.5em;
    grid-template-columns:
      minmax( 15px, calc( 50vw - 23rem ))
      repeat( 12, 1fr )
      minmax( 15px, calc( 50vw - 23rem ));

  }
  //@media (min-width: 992px)
  @include media-breakpoint-up(lg) {
    //max-width: 960px;
    grid-template-columns:
    minmax( 15px, calc( 50vw - 30.5rem ))
    repeat( 12, 1fr )
    minmax( 15px, calc( 50vw - 30.5rem ));
  }
  //@media (min-width: 1200px)
  @include media-breakpoint-up(xl) {
    //max-width: 1140px;
    grid-template-columns:
    minmax( 15px, calc( 50vw - 36rem ))
    repeat( 12, 1fr )
    minmax( 15px, calc( 50vw - 36rem ));
  }
  @media (min-width: 1680px) {
    grid-template-columns:
    minmax( 15px, calc( 50vw - 47rem ))
    repeat( 12, 1fr )
    minmax( 15px, calc( 50vw - 47rem ));
  }
}
 */
// fetaure Grid (3 col)
/* 
.feature-grid {
  display: grid;
  border-top:1px solid black;
  padding:2em 0;

  &:first-of-type {
    border-top:none;
  }
  .ref-content & {
    border-top:none;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 2fr 1fr 1fr;
    gap:1.5em;

    .second-third-col {
      grid-column: 2 / 4;
    }
    &.feature-grid--services {
      row-gap:0;
      
      >:nth-child(2) {
        grid-column: 2 / 3;
      }
      >:nth-child(3) {
        grid-column: 3 / 4;
      }
      >div:nth-child(4) {
        grid-column: 2 / 3;
      }
      >div:nth-child(5) {
        grid-column: 3 / 4;
      }
      .second-third-col {
        padding-top:2em;
      }
    }
    &.feature-grid--services-overview {
      row-gap:0;
      
      >:nth-child(2) {
        grid-column: 2 / 3;
        border-top:1px solid $gray-darker;
        padding-top:1em;
      }
      >:nth-child(3) {
        grid-column: 3 / 4;
        border-top:1px solid $gray-darker;
        padding-top:1.5em;
      }
    }
  }
  .fg-leistugen & {
    // border-top:2px solid #fff;
  }

  
  h2 {
    padding-right:1em;
  }
  ul {
    margin-top:0;
    padding-top:0;
    li {
      margin: 0 0 .5em 0;
    }
  }
  .image-holder {
    margin-bottom:1em;
   
    img {
      width: 100%;
      height: auto;
      max-width: 7em;
      border-radius: 50%;
    }
  }
}

 */



// profile Pages
.grid-fiftyFifty {
  display: grid;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    .left {
      grid-column: 1 /2;
      grid-row: 1;
    }
    .right {
      grid-column: 2 / 3;
      grid-row: 1;
    }
  
    &__reverse {
      grid-auto-flow: dense;
  
      div:nth-child(1) {
        grid-column: 2;
      }
    }
  }
}


// Special: The tooling logos grid 


.logo-grid {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  margin-top: 2em;
  margin-bottom: 5em;
  gap: 15px;
  max-width: 100%;

  &__item {
    display: flex;
    flex-direction: row;

    figure {
      margin: 0;
      padding: 0;
      display: flex;
      width: 100%;
      align-content: center;
      align-items: center;

      img {
        max-height: 3em;
        max-width: 4em;
      }

      figcaption {
        text-align: left;
        font-size: 1rem;
        line-height: 1.2;
        font-weight: 800;
      }
    }

  }
}


@include media-breakpoint-up(md) {

  .logo-grid {
    grid-template-columns: repeat(5, 17%);
    margin-top: 2em;
    margin-bottom: 5em;
    grid-column-gap:1.4em;

    &__item {
      flex-direction: column;

      figure {
        flex-direction: column;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        figcaption {
          text-align: center;
          font-size: .9rem;
        }
      }
    }
  }
}

// Special: The logo grid in features


.logo-grid--small {
  display: flex;
  flex-wrap:wrap;
  margin-top: 1em;
  margin-bottom: 2em;
  gap: 1em;
  max-width: 100%;
  align-items: center;
  
  &__item {
    display: flex;
    flex-direction: row;
    flex: 0 1 5em;

    figure {
      margin: 0;
      padding: 0;
      display: flex;
      max-width: 100%;
      align-content: center;
      align-items: center;

      img {
        max-height: 3em;
        max-width: 100%;
      }

      figcaption {
        display:none;
      }
    }

  }
}

@include media-breakpoint-up(md) {

  .logo-grid--small {
    grid-column-gap:1em;
  }
}

// basically a utility class:

.no-gap {
  gap:0;
}