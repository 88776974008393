// stretch the page full height of viewport
html {
  //font-size: 17px;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  overflow-x: hidden;
}

::selection {
  background: $red;
  color:white;
  text-shadow: none;
}


article {
  position: relative;
  padding: 0;
  background: $white;
  max-width: 100%;

  @include media-breakpoint-down(md) {
    overflow: hidden;
  }

  section {
    margin-bottom: 2em;
    padding: 6em 0;

    @include media-breakpoint-down(sm) {
      padding: 3em 0;
      margin-bottom: 1em;
    }

    &.pull-up-next {
      //padding-bottom:5em;
      margin-bottom: -5em;

      &--under {
        position: relative;
        z-index: 1;

        &+section {
          padding-top: 5em;
        }
      }

      &--over {
        position: relative;
        padding-bottom: 10em;
        margin-bottom: -5em;
        padding-bottom: 7em;

        @include media-breakpoint-down(sm) {
          margin-bottom: -6em;
          padding-bottom: 6em;
        }

        &+section {
          padding-top: 0em;
          z-index: 1;
          position: relative;
        }
      }
    }

    &.no-bottom-margin {
      margin-bottom: 0;
    }

    &.no-margin {
      margin-bottom: 0;
      margin-top: 0;
    }

    &.little-bottom-margin {
      margin-bottom: 3em;
    }

    &.big-bottom-margin {
      margin-bottom: 4em;
    }

    &.no-padding {
      padding-top: 0;
      padding-bottom:0;
    }
    &.little-top-padding {
      padding-top:2em;
    }
    &.no-bottom-padding {
      padding-bottom: 0;
    }
    &.little-bottom-padding,
    .little-bottom-padding {
      padding-bottom: 3em;
    }
    &.big-bottom-padding {
      padding-bottom:8em;
    }
    &.no-top-padding {
      padding-top:0;
    }
    &.no-top-margin {
      margin-top:0;
    }

    &.text-box>div {
      padding-top: 3em;
      padding-bottom: 2em;

      @include media-breakpoint-down(sm) {
        padding-top: 2em;
      }
    }   
  }
}

.pull-up-startimage,
section.pull-up-startimage {
  margin-top: -6em;
  
  @include media-breakpoint-up(lg) {
    margin-top: -10em;
  }
}


.home article {
  margin-top: 0vh;
}



// spacing der sections

.space-top-min {
  padding-top: 2em;
}

.space-top-max {
  padding-top: 12em;
}

// inmage offsetting
.offset-pull-up {
  position: relative;
  margin-bottom: 2em;

  @include media-breakpoint-up(sm) {
    margin-top: -10em;
  }
}

.margin-top-mobile {
  @include media-breakpoint-down(xs) {
    margin-top: 2em;
  }
}

// make it sticky
.sticky {
  position: sticky;
  top: 1em;
}


.quad-row {
  padding-top: 2em;
  padding-bottom: 2em;

  a.more {
    font-size: .85em;
    margin-bottom: 3em;
  }
}

.wrapper-outer {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .the-wrapper {
    flex: 1 1 auto;
  }

  footer {
    flex: 0 0 auto;
  }
}

.flex {
  display: flex;

  &--horizontal {
    flex-direction: horizontal;
    align-items: flex-start;
  }
}

img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.grayish {
  padding: 5em 0;

  @include media-breakpoint-down(sm) {
    padding: 3em 0;
  }

  background:$gray-light;
}

// smiley oben

.smiley-wrapper {
  margin-top: 4em;

  .smiley-holder {
    display: flex;
    max-width: 50%;
    margin: auto;
    align-items: flex-start;
    color:var(--red);

    img {
      display: block;
      color:var(--red);
    }
  }
}


.section-ref-overview {
  margin-bottom: 0;
}


// dark parts

.dark,
.red {
  background-color: black;
  color: white;

  h1,
  h2,
  h3,
  a,
  .more,
  h4 {
    color: white;
  }

  p,
  a,
  a p,
  li,
  h5,
  a h5,
  .fifth-heading {
    color: white;
  }

  .teaser__inner {
    border-color: transparent;
  }
}
.red {
  background-color:var(--red);

  .more:before {
    filter: grayscale(1) contrast(10);
  }
}

.upper-trenner {
  padding-top: 0;

  &:before {
    margin-bottom: 6em;
    content: "";
    display: block;
    height: 4em;
    background: $gray-light url("../img/upper-trenner-gray.svg") no-repeat right center;
    background-size: cover;
  }

  &--red {
    &:before {
      background: var(--red) url("../img/upper-trenner.svg") no-repeat right center;
    }
  }
}

.ampersand-light {
  background-image: url(../img/ampersand--light.svg);
  background-repeat: no-repeat;
  background-position: center;
}

// red-white after {

.red-white-after,
.white-red-after {
  position: relative;
  margin-bottom: 0;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;
    background: white;
    height: 1em;
    border-left: 50vw solid var(--red);

    @include media-breakpoint-down(sm) {
      height: .5em;
    }
  }
}

.white-red-after {
  &::after {
    border-left: 0;
    border-right: 50vw solid var(--red);
  }
}


// gradient

%gradient-transparent-black {
  $color_start: rgba(0, 0, 0, 0);
  $color_end: rgba(0, 0, 0, 0.5);
  $start_point: 40%;
  $end_point: 100%;
  background: white;
  /* Old browsers */
  background: -moz-linear-gradient(down, #{$color_start} #{$start_point}, #{$color_end} #{$end_point});
  /* FF3.6-15 */
  background: -webkit-linear-gradient(down, #{$color_start} #{$start_point}, #{$color_end} #{$end_point});
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #{$color_start} #{$start_point}, #{$color_end} #{$end_point});
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color_start}', endColorstr='#{$color_end}', GradientType=1);
  /* IE6-9 */
}
