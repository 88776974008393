// the header
// ===================================

.logo-container-wrapper {
  height: calc(90vh - 4em);
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  background: $brand-color;
  position: fixed;
  top: 0;
}

.logo-container {
  flex: 1 0 80%;
  height: 66vh;
  background: url('/img/Logo-horizontal.svg') no-repeat;
  background-position: bottom left;
  background-size: contain;
}

.arrow-container {
  flex: 1 1 10em;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  img,
  svg {
    width: 2em;
    height: auto;
    margin: 4em 5em;
  }
}

@include media-breakpoint-down(sm) {
  .logo-container-wrapper {
    flex-direction: column-reverse;
    height: calc(90vh - 4em);
  }

  .logo-container {
    flex: 1 1 auto;
    width: 100vw;
    background: url('/img/Logo-vertical.svg') no-repeat;
    background-position: bottom left;
    background-size: contain;
  }

  .arrow-container {
    flex: 0 0 8em;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    img {
      width: 1.6em;
      height: auto;
      margin: 1em;
    }
  }
}

.imprint-link {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: inline-block;
  color: #aaa;
  text-decoration: none;
  font-family: sans-serif;
}

// the Sticky nav 

.sticky-nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 12;

  @include media-breakpoint-down(sm) {
    //font-size: 70%;
  }

  .logo {
    position: absolute;
    top: 14px;
    left: 15px;
    
    @include media-breakpoint-up(md) {
      top: 2.0em;
      left: 2.0em;
    }
    @include media-breakpoint-up(lg) {
      top: 1.8em;
      left: 2.4em;
    }
    @include media-breakpoint-up(lg) {
      top: 1.6em;
      left: 2.4em;
    }

    img,
    svg { 
      width: 5em;
      height: 3.4em;
      
      @include media-breakpoint-up(lg) {
        width: 6.5em;
        height: 4.4em;
      }
      
      @include media-breakpoint-up(xl) {
        width: 7.4em;
        height: 4.9em;
      }
    }

    a {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex: 0 2 auto;

    li {
      a {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7em;
        width: 7em;
        border-radius: 50%;
        text-transform: uppercase;
        text-decoration: none;
        font: 700 .8em/1 $sans-serif-font;
        margin-left: 1.2em;
        color: $white;
        background: $gray-dark;
      }

      &:first-child a {
        background: $black;
      }
    }

    &.content {
      flex: 1 1 auto;
      align-items: flex-end;

      li {
        a {
          background: transparent;
          color: $text-color;
        }

        &.active a {
          color: $red;
        }
      }
    }
  }
}