// ref overview on homepage

.ref-overview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  grid-column-gap: 20px;
  grid-row-gap:20px;
  grid-auto-flow: dense;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 0;

  @include media-breakpoint-up(xl) {
    grid-column-gap: 40px;
    grid-row-gap:40px;
    grid-auto-flow: dense;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 0;
  }

  &__item {
    text-decoration: none !important;
    display: flex;
    flex-direction: column;
    position: relative;
 
    &--large {
      position: relative;
      grid-column: span 2;
      grid-row: span 2;
    } 
    &>div {
      display: block;
      position: relative;
    }

    .text-holder {
      /* position: absolute;
      bottom:0;
      opacity:0;
      transition: opacity .4s ease-out;
 */
      .text-box {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        padding: .7em .7em .7em 0;
        min-height:6em;

        @include media-breakpoint-up(xl) {
          min-height:4em;
        }
        h5,
        .fifth-heading,
        p  {
          margin-bottom: 0;
          color:white;
          font-size:1em;  
          font-size:var(--step-0);
          line-height:1.2;
          font-weight:400;
        }
        @include media-breakpoint-up(xl) {
            //font-size:1.1em;
        }
        

        span {
          display: none;
          color:white;
        }
      }
    }
    .img-holder {
      position: relative;
      max-width: 100%;
      display: flex;
      //align-items: center;
      //height:100%;
      overflow:hidden;

      img,
      picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .4s;
        transform:scale(1);
      }
      video {
        max-height: 100%;
        object-fit: cover;
        transition: all .4s;
        transform:scale(1);
      }
    }
    &--large {
      .img-holder {
        flex-grow:1;
      }
    }
  }
}

// slightly different on homepage:
.home {

  @include media-breakpoint-up(md) {

    .ref-overview__item--large:nth-child(1) {
      grid-column: 2 / -1;
    }
    .ref-overview__item--large:nth-child(3) {
      grid-column: 1 / 3;
    } 
  }

}


// on "work" page::after
@include media-breakpoint-up(md) {
  .ref-overview__item--large:nth-child(odd) {
    grid-column: 2 / -1;
  }
  .ref-overview__item--large:nth-child(1) {
    grid-column: 1 / 3;
  }
}

// animate the hover effect a litte

  .img-holder:before {
/*     content: "";
    color: white;
    font: 700 1.6em/1 $sans-serif-font;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .4s ease-out;
    opacity: 0;
    text-indent: 1em;

    @include media-breakpoint-up(xl) {
      content: url("data:image/svg+xml,%0A%3Csvg height='24' viewBox='0 0 46 24' width='46' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='stroke:%23ff263c;stroke-width:3;fill:none;fill-rule:evenodd;stroke-linecap:round;stroke-linejoin:round' transform='translate(2 2)'%3E%3Cpath d='m0 10h40'/%3E%3Cpath d='m32.2083333.20833333 9.5833334 9.58333334'/%3E%3Cpath d='m32.2083333 10.2083333 9.5833334 9.5833334' transform='matrix(-1 0 0 1 74 0)'/%3E%3C/g%3E%3C/svg%3E") " Projekt ansehen";
    }
 */  }
 
  /* a.ref-overview__item:hover .img-holder:before {
    background: fade-out(black, .4);
    opacity: 1;
    text-indent: 0;
  }
  a.ref-overview__item:hover .text-holder {
    opacity: 1;
  } */
  a.ref-overview__item:hover .img-holder {
    overflow:hidden;
    picture, img, video {
      transform: scale(1.03);  
    }
    video {
      transform: scale(1.05);
    }
  }
  a.ref-overview__item--large:hover .img-holder {
    overflow:hidden;
    picture, img {
      transform: scale(1.02);
    }
    video {
      transform: scale(1.05);
    }
  }

@include media-breakpoint-down(sm) {

  .ref-overview {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    background-size: contain;    

    &__item {
      grid-row: span 1;
      grid-column: span 1;
      padding-bottom:5px;

      &--large {
        grid-row: span 1;
        grid-column: span 1;
      }
    }  
  }
}