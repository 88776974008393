// Some special betics-specific styles

#betics-animated-screens {
  background: #E6EAEE;
  position: relative;

  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
  }

  .holder {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 2.4;
    position: relative;

    &_1 {
      top: 20%;
      left: -10%;
    }

    &_2 {
      top: -23%;
      left: 12%;
    }

    &_3 {
      grid-column: 2 / 3;
      top: -20%;
      left: -7%;

      @include media-breakpoint-up(sm) {
        margin-bottom: -22%;
      }
    }

  }
}


.betics-ani-holder {
  position: relative;

  picture {
    position: relative;
    z-index: 2;
  }

  img.betics-ani {
    position: absolute;
    max-width: 87.1%;
    top: 9.2%;
    left: 6.4%;
    z-index: 1;
  }
}

// All the betics CSS animation and start points for gsap

.animation-betics {

  @include media-breakpoint-down(sm) {
    .grid.grid--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .circle {

    &--1 {
      transform: rotate(-160deg);
    }

    &--2 {
      transform: rotate(-125deg);
    }

    &--3 {
      transform: rotate(160deg);
    }

    &--4 {
      transform: rotate(-160deg);
    }

    &--5 {
      transform: rotate(-160deg);
    }

    &--6 {
      transform: rotate(90deg);
    }

    &--7 {
      transform: rotate(-160deg);
    }

    &--8 {
      transform: rotate(-110deg);
    }
  }
}

// Le Logo Animation

.betics-logo-animation {
  max-width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0 4em;


  @include media-breakpoint-up(md) {
    max-width: 100%;
    margin-top: 4em;
    margin-bottom: 4em;
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 6em;
    margin-bottom: 6em;
  }
}

#on_button {
  fill: #ff9900;
  animation: color-change--button 4.8s infinite steps(1) both;
}

#on_dash {
  fill: #ff9900;
  animation: color-change--dash 4.8s infinite steps(1) both;
}


@keyframes color-change--button {
  0% {
    fill: #00D1E5;
  }

  20% {
    fill: #007858;
  }

  40% {
    fill: #FFBD1D;
  }

  60% {
    fill: #3239B2;
  }

  80% {
    fill: #9013FE;
  }

  100% {
    fill: #FF8236;
  }
}

@keyframes color-change--dash {
  0% {
    fill: #D7016A;
  }

  20% {
    fill: #FF8236;
  }

  40% {
    fill: #D7016A;
  }

  60% {
    fill: #00C891;
  }

  80% {
    fill: #FF8236;
  }

  100% {
    fill: #00D1E5;
  }
}