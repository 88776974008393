// Tiny slider

.tns-ovh {
    overflow: visible !important;
    overflow-y: hidden;
}
.tns-nav {
    //border:1px solid red;
    button {
        width: clamp(30px,5vw,6em);
        height: 2em;
        margin-right: clamp(15px, 1vw, 30em);
        border: none;
        transition: all .4s;
        background:transparent;
        position: relative;

        &:after {
            content:"";
            position:absolute;
            top:calc(50% - 0.15em);
            left:0;
            display:block;
            width:100%;
            height:0.3em;
            background: #000;
        }
        
        &:hover {
            opacity:.6;
        }

        &.tns-nav-active{
            &:after {
            background:var(--red);
            }
            &:hover {
                opacity:1 !important;
            }
        }
    }
}

#tns1-iw {
    margin-left:0 !important;
    margin-right:0 !important;
}
#tns1 > .tns-item {
    padding-right: 15px;
  }