// say hello CTA 

.say-hello {

    &__wrapper {
        padding:clamp(3em, 8vw, 8em) 0;
        display:flex;
        justify-content: center;
        a.more {
            //font-size:clamp(2.7em, 10.5vw, 9em);
            font-size:var(--step-7);
            font-weight:900;
            text-decoration:none;
            position: relative;
        
            &:before {
                height: 0.5em;
                width: 1.3em;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -0.15em;
                left: 1.35em;
                width: 0;
                height: 0.05em;
                background: var(--red);
                display: block;
                transition: all .2s ease-out;
            }
            &:hover {
                &:after {
                    width: calc( 100% - 1.35em);
                }
            }
        }    
    }

}