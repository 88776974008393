// the Inter,
// classic and variable font


@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Inter/Inter-Thin-BETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Thin-BETA.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  src: url("../fonts/Inter/Inter-ThinItalic-BETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ThinItalic-BETA.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/Inter/Inter-ExtraLight-BETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraLight-BETA.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/Inter/Inter-ExtraLightItalic-BETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraLightItalic-BETA.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Inter/Inter-Light-BETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Light-BETA.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Inter/Inter-LightItalic-BETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-LightItalic-BETA.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Inter/Inter-Italic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Inter/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Inter/Inter-MediumItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-MediumItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Inter/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-SemiBold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/Inter/Inter-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-SemiBoldItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Inter/Inter-Bold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/Inter/Inter-BoldItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-BoldItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Inter/Inter-ExtraBold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraBold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/Inter/Inter-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraBoldItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Inter/Inter-Black.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Black.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/Inter/Inter-BlackItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-BlackItalic.woff") format("woff");
  font-display: swap;
}

/* -------------------------------------------------------
  Variable font.
  Usage:
  
    html { font-family: 'Inter', sans-serif; }
    @supports (font-variation-settings: normal) {
      html { font-family: 'Inter var', sans-serif; }
    }
  */

// we coll this font explicitly in the head via "preload"

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("../fonts/Inter/Inter-upright.var.woff2") format("woff2");
  font-display: swap;
}

/*
  @font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url("../fonts/Inter/Inter-italic.var.woff2") format("woff2");
    font-display: swap;

  }
  */

/* --------------------------------------------------------------------------
  [EXPERIMENTAL] Multi-axis, single variable font.
  
  Slant axis is not yet widely supported (as of February 2019) and thus this
  multi-axis single variable font is opt-in rather than the default.
  
  When using this, you will probably need to set font-variation-settings
  explicitly, e.g.
  
    * { font-variation-settings: "slnt" 0deg }
    .italic { font-variation-settings: "slnt" 10deg }
  
  */
/*
  @font-face {
    font-family: 'Inter var experimental';
    font-weight: 100 900;
    font-style: oblique 0deg 10deg;
    src: url("../fonts/Inter/Inter.var.woff2") format("woff2");
    font-display: swap;
  }
  */