// Firenio Animation

#firenio-ani {
    
    .item {
        opacity:.2;
    }
    
}

// placement in reference site

.animation-container {
    position: relative;
    picture {
        position: relative;
    }
    .start-animation-1 {
        position: absolute;
        display: block;
        height: auto;
        width: 27%;
        top: 50%;
        left: 14.5%;
        transform: translateY(-21%);
    }
    .start-animation-2 {
        position: absolute;
        display: block;
        height: auto;
        width:27%;
        top: 50%;
        transform: translateY(-56%);
        right: 15%;
    }
}