// All the betics CSS animation and start points for gsap

.flyer-animation {
    position: relative;
    width: 100%;

    .flyer {
        position: absolute;
        display: block;
        top: 50%;
        left: -20%;
        width: 18%;
        height: 0;
        padding-bottom: 30%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;


        &_1 {
            background-image: url('/case-studies/webdesign-dmfv/img/flyer_1.svg');
            transform: translateY(-70%);
            z-index: 12;
        }

        &_2 {
            background-image: url('/case-studies/webdesign-dmfv/img/flyer_2.svg');
            transform: translateY(-60%) translateX(-40%);
            z-index: 7;
        }

        &_3 {
            background-image: url('/case-studies/webdesign-dmfv/img/flyer_3.svg');
            transform: translateY(-30%);
            z-index: 1;
        }

        &_4 {
            background-image: url('/case-studies/webdesign-dmfv/img/flyer_4.svg');
            transform: translateY(0) translateX(-50%);
            z-index: 9;
        }

        &_5 {
            background-image: url('/case-studies/webdesign-dmfv/img/flyer_5.svg');
            transform: translateY(30%);
            z-index: 2;
        }

        &_6 {
            background-image: url('/case-studies/webdesign-dmfv/img/flyer_6.svg');
            transform: translateY(60%) translateX(-10%);
            z-index: 11;
        }
    }
}