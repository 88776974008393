/* @link https://utopia.fyi/type/calculator?c=320,18,1.125,2000,20,1.333,7,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
/* 
:root {
    --step--2: clamp(0.7035rem, 0.9242rem + -0.1766vi, 0.8889rem);
    --step--1: clamp(0.9377rem, 1.0119rem + -0.0593vi, 1rem);
    --step-0: clamp(1.125rem, 1.1012rem + 0.119vi, 1.25rem);
    --step-1: clamp(1.2656rem, 1.1893rem + 0.3815vi, 1.6663rem);
    --step-2: clamp(1.4238rem, 1.272rem + 0.7593vi, 2.2211rem);
    --step-3: clamp(1.6018rem, 1.343rem + 1.2942vi, 2.9607rem);
    --step-4: clamp(1.802rem, 1.3935rem + 2.0425vi, 3.9467rem);
    --step-5: clamp(2.0273rem, 1.4114rem + 3.0796vi, 5.2609rem);
    --step-6: clamp(2.2807rem, 1.3793rem + 4.5068vi, 7.0128rem);
    --step-7: clamp(2.5658rem, 1.2739rem + 6.4593vi, 9.3481rem);
  }
 */
/* @link https://utopia.fyi/type/calculator?c=320,16,1.125,2000,20,1.333,7,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
/* 
:root {
    --step--2: clamp(0.7035rem, 0.8066rem + -0.0825vi, 0.7901rem);
    --step--1: clamp(0.8889rem, 0.8796rem + 0.0465vi, 0.9377rem);
    --step-0: clamp(1rem, 0.9524rem + 0.2381vi, 1.25rem);
    --step-1: clamp(1.125rem, 1.0219rem + 0.5155vi, 1.6663rem);
    --step-2: clamp(1.2656rem, 1.0836rem + 0.91vi, 2.2211rem);
    --step-3: clamp(1.4238rem, 1.1311rem + 1.4637vi, 2.9607rem);
    --step-4: clamp(1.6018rem, 1.1552rem + 2.2332vi, 3.9467rem);
    --step-5: clamp(1.802rem, 1.1432rem + 3.2942vi, 5.2609rem);
    --step-6: clamp(2.0273rem, 1.0777rem + 4.7481vi, 7.0128rem);
    --step-7: clamp(2.2807rem, 0.9345rem + 6.7308vi, 9.3481rem);
  }
 */
  /* @link https://utopia.fyi/type/calculator?c=320,17,1.125,2000,20,1.333,7,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    --step--2: clamp(0.7035rem, 0.8654rem + -0.1296vi, 0.8395rem);
    --step--1: clamp(0.9377rem, 0.9457rem + -0.0064vi, 0.9444rem);
    --step-0: clamp(1.0625rem, 1.0268rem + 0.1786vi, 1.25rem);
    --step-1: clamp(1.1953rem, 1.1056rem + 0.4485vi, 1.6663rem);
    --step-2: clamp(1.3447rem, 1.1778rem + 0.8347vi, 2.2211rem);
    --step-3: clamp(1.5128rem, 1.237rem + 1.379vi, 2.9607rem);
    --step-4: clamp(1.7019rem, 1.2743rem + 2.1379vi, 3.9467rem);
    --step-5: clamp(1.9147rem, 1.2773rem + 3.1869vi, 5.2609rem);
    --step-6: clamp(2.154rem, 1.2285rem + 4.6274vi, 7.0128rem);
    --step-7: clamp(2.4232rem, 1.1042rem + 6.5951vi, 9.3481rem);
  }